.logo-container {
    display: inline-block
  }
  
.logo-container img {
    float: left;
    width: 60px;
    height: 60px;
  }
  
.logo-container h1 {
    float: left;
    top: 18px;
    left: 10px;
    padding: 10px;
  }